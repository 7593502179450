<p-toast />
<div class="page-container user-container">
  <div class="header-container">
    <app-search-text (searchEvent)="handleSearch($event)" class="header" />
    <app-button
      label="Send Invitation"
      (clickEvent)="handleAddNew()"
      class="header"
    />
  </div>

  <p-table
    [columns]="cols"
    [value]="userList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td style="width: 20%">{{ rowData.name }}</td>
        <td style="width: 40%">
          <div class="email-container">
            <img src="assets/email.icon.svg" />
            <div style="font-size: 0.9rem">{{ rowData.email }}</div>
          </div>
        </td>
        <td style="width: 20%">{{ rowData.appAccessLevel }}</td>
        <td style="width: 15%">
          <p-tag
            [value]="rowData.status"
            [severity]="statusTag(rowData.status)"
          />
        </td>
        <td
          style="width: 5%"
          style="display: flex; justify-content: end"
          (click)="op.toggle($event)"
        >
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-overlayPanel #op>
            <ng-template pTemplate="content">
              <!-- <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div> -->
              <div
                (mouseover)="handleHover(2)"
                (click)="handleDelete(rowData)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="userList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="6">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '20vw' }"
  >
    <ng-template pTemplate="header">
      <h3></h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form [formGroup]="formData">
        <div style="display: flex; gap: 1rem; margin: 1rem 0">
          <p-checkbox formControlName="web" label="Web" [binary]="true" />
          <p-checkbox formControlName="app" label="App" [binary]="true" />
        </div>
        <app-input
          label="User Name"
          [control]="formData.get('name')"
          [required]="true"
        />
        <app-input
          label="Email"
          [control]="formData.get('email')"
          [required]="true"
        />
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Send Invitation"
        (clickEvent)="onSave()"
        [disabled]="formData.invalid"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>
