import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  constructor(private api: ApiService) {}

  async fetchUserProfileData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get(`api/Installer`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateUserProfileData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.put(`api/Installer`, payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateUserProfileImage(payload: any): Promise<any> {
    try {
      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      const data = await firstValueFrom(
        this.api.post(`api/installer-users/image`, formData)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCompanyLogo(payload: any): Promise<any> {
    try {
      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      const data = await firstValueFrom(
        this.api.post(`api/installer/logo`, formData)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateUserStatus(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(`api/installer-users/set-active`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
