<div class="page-container task-container">
  <p-toast />
  <div class="header-container">
    <!-- <app-button label="Add" (clickEvent)="handleAddDialog()" class="header" /> -->
  </div>

  <p-table
    [columns]="cols"
    dataKey="id"
    [value]="installerTaskList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td style="font-weight: 500">{{ rowData.tasks.name }}</td>
        <td>{{ rowData.tasks.deviceType }}</td>
        <td>{{ rowData.tasks.isReplace ? "Replace" : "New" }}</td>
        <td>
          <span
            *ngFor="let catalogue of rowData.serviceCatalogues; let i = index"
          >
            {{ catalogue?.serviceItem }}
            <span *ngIf="i < rowData.serviceCatalogues.length - 1">, </span>
          </span>
        </td>
        <td
          style="width: 5%"
          style="display: flex; justify-content: end"
          (click)="op.toggle($event)"
        >
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-overlayPanel #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div>
            </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="emptymessage"
      *ngIf="installerTaskList.length === 0"
    >
      <tr *ngIf="!isLoading">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="taskDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '35vw' }"
  >
    <ng-template pTemplate="header">
      <h3>{{ edit ? "Edit" : "Add" }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div>
          <label class="input-label">
            Service Catalogue
            <span class="required-label">*</span>
          </label>
          <p-multiSelect
            [options]="serviceCatalogueList"
            formControlName="serviceCatalogueIds"
            optionLabel="serviceItem"
            placeholder="Select"
            appendTo="body"
            optionValue="id"
          />
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        *ngIf="!edit"
        label="Save"
        [disabled]="formData.invalid"
        (clickEvent)="onSave()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
      <app-button
        *ngIf="edit"
        label="Update"
        [disabled]="formData.invalid"
        (clickEvent)="onUpdate()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>
