import { Component } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { SharedModule } from '../../shared.module';
import { CataloguePriceType, CatalogueType, TableCols } from '../../types';
import {
  PRICE_COLS,
  PRODUCT_CATALOGUE,
  SERVICE_CATALOGUE,
} from '../../constants';
import { MessageService } from 'primeng/api';
import { CatalogueService } from './catalogue.service';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment from 'moment';
import { ButtonComponent } from '../../Components/button/button.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NumberInputComponent } from '../../Components/number-input/number-input.component';
import { InputComponent } from '../../Components/input/input.component';

@Component({
  selector: 'app-catalogue-management',
  standalone: true,
  templateUrl: './catalogue-management.component.html',
  styleUrl: './catalogue-management.component.scss',
  providers: [MessageService],
  imports: [
    TabViewModule,
    SharedModule,
    SearchFieldComponent,
    ButtonComponent,
    NumberInputComponent,
    InputComponent,
  ],
})
export class CatalogueManagementComponent {
  productCols: TableCols[] = PRODUCT_CATALOGUE;
  CatalogueList: CatalogueType[] = [];
  priceCols: TableCols[] = PRICE_COLS;
  catalogueCount: number = 0;
  isLoading: boolean = true;
  first = 0;
  pageNumber: number = 0;
  currentPageSize: number = 10;
  skeletonRows = new Array(this.currentPageSize);
  currentPricePageSize: number = 0;
  priceCount: number = 0;
  searchSubject: Subject<string> = new Subject<string>();
  priceList: CataloguePriceType[] = [];
  searchText: string | null = null;
  serviceList: any[] = [];
  serviceCols: TableCols[] = SERVICE_CATALOGUE;
  onHover: number = 0;
  onSaveLoad: boolean = false;
  deleteDialog: boolean = false;
  edit: boolean = false;
  serviceDialog: boolean = false;
  formData!: FormGroup;
  serviceRowData: any;

  async addtoFav(data: CatalogueType) {
    try {
      const val = {
        id: data.id,
        isFavourite: !data.isFavourite,
        model: data.model,
        manufacturer: data.manufacturer,
        deviceType: data.deviceType,
        eligibility: {
          regulator: 'IPART',
          eligibleFrom: moment().format('YYYY-MM-DD'),
          eligibleTo: moment().format('YYYY-MM-DD'),
          costPrice: 0,
          margin: 0,
          marginAmount: 0,
          rrp: 0,
          catalogueEntryId: data.id,
        },
      };
      const res = await this.service.updateCatalogueData(
        this.pageNumber,
        this.currentPageSize,
        val
      );
      if (res) {
        this.CatalogueList = await this.getCatalogueData(
          this.pageNumber,
          this.currentPageSize
        );
        this.priceList = await this.getPriceData();
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  constructor(
    private messageService: MessageService,
    private service: CatalogueService,
    private formBuilder: FormBuilder
  ) {
    this.setupSearch();
    this.initialFormData();
  }

  initialFormData() {
    this.formData = this.formBuilder.group({
      serviceItem: ['', Validators.required],
      price: ['', Validators.required],
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  handleAddDialog(): void {
    this.serviceDialog = true;
    this.edit = false;
    this.formData.reset();
  }

  closeDialog(): void {
    this.serviceDialog = false;
    this.deleteDialog = false;
  }

  private setupSearch() {
    this.searchSubject.pipe(debounceTime(500)).subscribe((value: string) => {
      this.searchText = value.trim();
      this.pageNumber = 0;
      this.first = 0;
      this.handleSearch(this.pageNumber, this.currentPageSize, value.trim());
    });
  }

  async handleSearch(
    pageNo: number,
    pageSize: number,
    val: string
  ): Promise<void> {
    try {
      if (val !== '') {
        const res = await this.service.fetchCatalogueData(
          pageNo,
          pageSize,
          val
        );
        if (res) {
          this.CatalogueList = res.data;
          this.catalogueCount = res.totalCount;
        }
      } else {
        this.CatalogueList = await this.getCatalogueData(pageNo, pageSize);
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  async ngOnInit() {
    this.CatalogueList = await this.getCatalogueData(0, 10);
    this.priceList = await this.getPriceData();
    this.serviceList = await this.getServiceData();
  }

  async getCatalogueData(
    pageNo: number,
    pageSize: number
  ): Promise<CatalogueType[]> {
    try {
      const res = await this.service.fetchCatalogueData(pageNo, pageSize, '');
      if (res) this.isLoading = false;
      this.catalogueCount = res.totalCount;
      return res.data;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  async getPriceData(): Promise<CataloguePriceType[]> {
    try {
      const res = await this.service.fetchPriceData();
      if (res) this.isLoading = false;
      return res.data;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  async pageChange(event: any): Promise<void> {
    this.pageNumber = event.first / event.rows;
    this.first = event.first;
    if (this.searchText) {
      this.handleSearch(this.pageNumber, this.currentPageSize, this.searchText);
    } else {
      this.CatalogueList = await this.getCatalogueData(
        this.pageNumber,
        this.currentPageSize
      );
    }
  }

  async onPageSizeChange(event: any) {
    this.currentPageSize = event.value;
    if (this.searchText) {
      this.handleSearch(this.pageNumber, event.value, this.searchText);
    } else {
      this.CatalogueList = await this.getCatalogueData(
        this.pageNumber,
        event.value
      );
    }
  }

  async onRowPriceSave(rowData: CataloguePriceType) {
    const { costPrice, margin } = rowData.eligibility;
    try {
      const payload = {
        ...rowData,
        eligibility: {
          costPrice,
          margin,
          marginAmount: (costPrice * (margin || 0)) / 100,
          rrp: costPrice + (costPrice * (margin || 0)) / 100,
        },
      };
      const res = await this.service.updateCatalogueData(
        this.pageNumber,
        this.currentPageSize,
        payload
      );
      if (res) {
        this.priceList = await this.getPriceData();
        this.successToast('Price Updated Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async getServiceData(): Promise<any[]> {
    this.isLoading = true;
    try {
      const data = await this.service.fetchServiceCatalogueData();
      if (data) {
        this.isLoading = false;
      }
      return data;
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
      return [];
    }
  }

  handleEdit(rowData: any): void {
    this.serviceDialog = true;
    this.serviceRowData = rowData;
    this.formData.patchValue(rowData);
    this.edit = true;
  }

  handleDelete(rowData: any): void {
    this.deleteDialog = true;
    this.serviceRowData = rowData;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const data = await this.service.createServiceCatalogueData(
        this.formData.value
      );
      if (data) {
        this.serviceDialog = false;
        this.onSaveLoad = false;
        this.serviceList = await this.getServiceData();
        this.successToast('Service Item created Successfully');
      }
      return data;
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.formData.value,
        id: this.serviceRowData.id,
      };
      const data = await this.service.updateServiceCatalogueData(payload);
      if (data) {
        this.serviceDialog = false;
        this.onSaveLoad = false;
        this.serviceList = await this.getServiceData();
        this.successToast('Service Item updated Successfully');
      }
      return data;
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onDeleteService(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const data = await this.service.deleteServiceCatalogueData(
        this.serviceRowData.id
      );
      if (data) {
        this.deleteDialog = false;
        this.onSaveLoad = false;
        this.serviceList = await this.getServiceData();
        this.successToast('Service Item Deleted Successfully');
      }
      return data;
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }
}
