import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';
import { UserType } from '../../types';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private api: ApiService) {}

  async fetchUserData(): Promise<any> {
    try {
      const data: UserType[] = await firstValueFrom(
        this.api.get(`api/installer-users`)
      );
      const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
      return sortedData;
    } catch (error) {
      throw error;
    }
  }

  async createUserData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/installer/users/invite`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
