import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ChartModule } from 'primeng/chart';
import { DropDownType } from '../../../types';
import moment from 'moment';
import { DashboardService } from '../dashboard.service';
import { CERTIFICATE_VALUE } from '../../../constants';
import { SharedModule } from '../../../shared.module';

@Component({
  selector: 'app-certificate-value',
  standalone: true,
  imports: [SharedModule, ChartModule],
  providers: [MessageService],
  templateUrl: './certificate-value.component.html',
  styleUrls: ['./certificate-value.component.scss'],
})
export class CertificateValueComponent {
  price: any;
  options: any;
  allPrice: any[] = [];
  CertificateList: DropDownType[] = CERTIFICATE_VALUE;
  certificateType: any = CERTIFICATE_VALUE[0].label;

  constructor(
    private messageService: MessageService,
    private service: DashboardService
  ) {}

  async ngOnInit() {
    this.allPrice = await this.getAllCertificatePrice(this.certificateType);
    this.initializeChartOptions();
    this.updateChartData();
  }

  async getAllCertificatePrice(type: string) {
    try {
      const data = await this.service.fetchAllCertificatePrice(type);
      return data;
    } catch (error: any) {
      console.error('Error fetching certificate prices:', error);
      return [];
    }
  }

  updateChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const formattedLabel = this.getLastSixMonthsWithDates();
    const label = this.labelFormat();

    const groupedData = this.groupDataByAggregator(this.allPrice);
    const alignedData = this.alignDataWithLabels(groupedData, formattedLabel);

    const datasets = [
      {
        label: 'Market Price',
        data: alignedData.marketPrice,
        fill: true,
        borderColor: documentStyle.getPropertyValue('--blue-500'),
        tension: 0.4,
        spanGaps: true,
      },
      {
        label: 'Installer Price',
        data: alignedData.installerPrice,
        fill: true,
        borderColor: documentStyle.getPropertyValue('--orange-500'),
        tension: 0.4,
        backgroundColor: 'rgba(255,167,38,0.2)',
        spanGaps: true,
      },
    ];

    Object.keys(alignedData.aggregatorPrices).forEach((aggregatorId, index) => {
      const aggregatorName = groupedData.aggregatorNames[aggregatorId] || '';
      datasets.push({
        label: aggregatorName,
        data: alignedData.aggregatorPrices[aggregatorId],
        fill: true,
        borderColor: this.getDynamicColor(index),
        tension: 0.4,
        spanGaps: true,
      });
    });

    this.price = {
      labels: label,
      datasets: datasets,
    };
  }

  groupDataByAggregator(data: any[]) {
    const grouped = {
      marketPrice: [] as { date: string; value: number | null }[],
      installerPrice: [] as { date: string; value: number | null }[],
      aggregatorPrices: {} as {
        [key: string]: {
          date: string;
          value: number | null;
          name: string | null;
        }[];
      },
      aggregatorNames: {} as { [key: string]: string | null },
    };

    data.forEach((item) => {
      grouped.marketPrice.push({
        date: item.CertificateDate,
        value: item.MarketPrice,
      });

      grouped.installerPrice.push({
        date: item.CertificateDate,
        value: item.InstallerPrice,
      });

      if (item.AggregatorId != null) {
        if (!grouped.aggregatorPrices[item.AggregatorId]) {
          grouped.aggregatorPrices[item.AggregatorId] = [];
          grouped.aggregatorNames[item.AggregatorId] = item.AggregatorName; // Store the name for the AggregatorId
        }
        grouped.aggregatorPrices[item.AggregatorId].push({
          date: item.CertificateDate,
          value: item.AggregatorPrice,
          name: item.AggregatorName,
        });
      }
    });

    return grouped;
  }

  alignDataWithLabels(groupedData: any, labels: string[]) {
    const alignedData = {
      marketPrice: Array(labels.length).fill(null),
      installerPrice: Array(labels.length).fill(null),
      aggregatorPrices: {} as { [key: string]: any[] },
    };

    labels.forEach((label, index) => {
      groupedData.marketPrice.forEach((item: any) => {
        if (item.date === label) {
          alignedData.marketPrice[index] = item.value;
        }
      });

      groupedData.installerPrice.forEach((item: any) => {
        if (item.date === label) {
          alignedData.installerPrice[index] = item.value;
        }
      });

      Object.keys(groupedData.aggregatorPrices).forEach((aggregatorId) => {
        if (!alignedData.aggregatorPrices[aggregatorId]) {
          alignedData.aggregatorPrices[aggregatorId] = Array(
            labels.length
          ).fill(null);
        }

        groupedData.aggregatorPrices[aggregatorId].forEach((item: any) => {
          if (item.date === label) {
            alignedData.aggregatorPrices[aggregatorId][index] = item.value;
          }
        });
      });
    });

    return alignedData;
  }

  private getLastSixMonthsWithDates(): string[] {
    const result: string[] = [];

    for (let i = 5; i >= 0; i--) {
      const currentMonth = moment().subtract(i, 'months');
      const daysInMonth = currentMonth.daysInMonth();

      for (let day = 1; day <= daysInMonth; day++) {
        result.push(currentMonth.clone().date(day).format('YYYY-MM-DD'));
      }
    }

    return result;
  }

  private labelFormat(): string[] {
    const result: string[] = [];

    for (let i = 5; i >= 0; i--) {
      const currentMonth = moment().subtract(i, 'months');
      const monthLabel = currentMonth.format('MMMM');
      const daysInMonth = currentMonth.daysInMonth();

      for (let day = 1; day <= daysInMonth; day++) {
        result.push(`${monthLabel}`);
      }
    }

    return result;
  }

  initializeChartOptions() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context: { raw: any; dataset: { label: any } }) {
              const value = context.raw;
              return `${context.dataset.label}: $${value.toFixed(2)}`;
            },
          },
        },
        legend: {
          labels: { color: textColor },
        },
      },
      scales: {
        x: {
          ticks: {
            source: 'auto',
            maxTicksLimit: 6,
            color: textColorSecondary,
          },
          grid: { color: surfaceBorder, drawBorder: false },
        },
        y: {
          ticks: {
            color: textColorSecondary,
            callback: function (value: number) {
              return `$${value.toFixed(2)}`;
            },
          },
          grid: { color: surfaceBorder, drawBorder: false },
          beginAtZero: true,
        },
      },
    };
  }

  private getDynamicColor(index: number): string {
    const colors = [
      '#FF1493',
      '#008000',
      '#9400D3',
      '#FFD700',
      '#E74C3C',
      '#7FFF00',
      '#AF7AC5',
      '#FF4500',
      '#9932CC',
      '#45B39D',
    ];
    return colors[index % colors.length];
  }

  async onCertificateTypeChange(event: any): Promise<void> {
    this.allPrice = await this.getAllCertificatePrice(event.value);
    this.initializeChartOptions();
    this.updateChartData();
  }
}
