<div>
  <div class="flex" style="gap: 1rem">
    <div class="map-container">
      <div class="heading">Jobs</div>
      <div id="map"></div>
    </div>
    <div class="bar-chart-container">
      <app-certificate-count />
    </div>
  </div>

  <app-certificate-value />
</div>
