import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { ProjectType, TableCols } from '../../types';
import { AUDIT_COLS } from '../../constants';
import { NavigationExtras, Router } from '@angular/router';
import { ProjectService } from '../project-management/project.services';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MessageService } from 'primeng/api';
import { AuditService } from './audit.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import JSZip from 'jszip';

@Component({
  selector: 'app-audit',
  standalone: true,
  imports: [SharedModule],
  providers: [MessageService],
  templateUrl: './audit.component.html',
  styleUrl: './audit.component.scss',
})
export class AuditComponent {
  cols: TableCols[] = AUDIT_COLS;
  ProjectList: any[] = [];
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  onHover: number = 0;
  StatusList: any[] = [
    { label: 'AUDIT' },
    { label: 'AUDITFAIL' },
    { label: 'AUDITPASS' },
  ];
  searchQuery: string[] | null = [];
  searchSubject: Subject<string> = new Subject<string>();
  onGeneratePdf: boolean = false;
  onDownloadPdf: boolean = false;

  constructor(
    private router: Router,
    private service: ProjectService,
    private messageService: MessageService,
    private auditService: AuditService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    this.ProjectList = await this.projectData();
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  private async projectData(): Promise<ProjectType[]> {
    try {
      const data = await this.service.fetchProjectData(0, 1000, '', null);
      if (data) this.isLoading = false;
      const auditJobs = data.data
        .map((project: any) => {
          return {
            ...project,
            jobs: project.jobs.filter(
              (job: any) =>
                job.status === 'AUDIT' ||
                job.status === 'AUDITFAIL' ||
                job.status === 'AUDITPASS'
            ),
          };
        })
        .filter((project: any) => project.jobs.length > 0);
      return auditJobs;
    } catch (error: any) {
      this.isLoading = false;
      return [];
    }
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'AUDIT':
        return 'tag-audit';
      case 'AUDITPASS':
        return 'tag-complete';
      case 'AUDITFAIL':
        return 'tag-paid';
      default:
        return '';
    }
  }

  audit(jobData: any): any {
    const params: NavigationExtras = {
      state: { jobData },
    };
    this.router.navigate([`audit/${jobData.id}`], params);
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async generatePdf(rowData: any): Promise<void> {
    this.onGeneratePdf = true;
    try {
      const data = await this.auditService.generateAuditPdf({
        jobId: rowData.id,
      });
      if (data) {
        this.onGeneratePdf = false;
        this.successToast('PDF Generated Successfully');
      }
    } catch (error: any) {
      this.onGeneratePdf = false;
      this.errorToast(error.messageService);
    }
  }

  async downloadPdf(rowData: { id: number; jobName: string }): Promise<void> {
    this.onDownloadPdf = true;
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });
    try {
      const response: any = await this.http
        .get(`${environment.baseUrl}/api/audit/get-pdfs?jobId=${rowData.id}`, {
          headers,
          responseType: 'arraybuffer',
        })
        .toPromise();

      const zip = new JSZip();
      await zip.loadAsync(response);
      const imagePromises = Object.keys(zip.files).map(async (filename) => {
        const file = zip.files[filename];
        const blob = await file.async('blob');
        const url = URL.createObjectURL(blob);
        this.downloadFile(url, filename);
        return url;
      });
      this.onDownloadPdf = false;
    } catch (error: any) {
      this.onDownloadPdf = false;
      this.errorToast(error.message);
    }
  }

  private downloadFile(url: string, filename: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
}
