import ROUTES from './routes.const';

export const SideBarList = [
  {
    label: 'Dashboard',
    key: 'dashboard',
    link: ROUTES.DASHBOARD,
    icon: './assets/SidebarIcon/dashboard.svg',
  },
  {
    label: 'Project Management',
    key: 'project-management',
    link: ROUTES.PROJECT_MANAGEMENT,
    icon: './assets/SidebarIcon/Project.svg',
  },
  {
    label: 'Audit',
    key: 'audit',
    link: ROUTES.AUDIT,
    icon: './assets/SidebarIcon/audit.svg',
  },
  {
    label: 'User Management',
    key: 'user-management',
    link: ROUTES.USER_MANAGEMENT,
    icon: './assets/SidebarIcon/user.svg',
  },
  {
    label: 'Catalogue Management',
    key: 'catalogue-management',
    link: ROUTES.CATALOGUE_MANAGEMENT,
    icon: './assets/SidebarIcon/Catalogue.svg',
  },
  {
    label: 'Schedules',
    key: 'schedules',
    link: ROUTES.SCHEDULES,
    icon: './assets/SidebarIcon/schedule.svg',
  },
  {
    label: 'Certificate Value',
    key: 'certificate-value',
    link: ROUTES.CERTIFICATE_VALUE,
    icon: './assets/SidebarIcon/CertificateValue.svg',
  },
  {
    label: 'Task Management',
    key: 'task-management',
    link: ROUTES.TASK_MANAGEMENT,
    icon: './assets/SidebarIcon/task.icon.svg',
  },
];

export const PROJECT_COLS = [
  { header: 'Project Name' },
  { header: 'Customer' },
  { header: 'Address' },
  { header: 'Status' },
  { header: '' },
];

export const JOB_COLS = [
  { header: 'Job' },
  { header: 'Location' },
  { header: 'Dwelling Type' },
  { header: 'Status' },
  { header: '' },
];

export const FORMS_COLS = [
  { header: '' },
  { header: 'Name' },
  { header: 'Type' },
  { header: 'Status' },
  { header: '' },
];

export const DOCUMENT_VERSION = [
  { header: 'Version' },
  { header: 'Status' },
  { header: 'Email Status' },
  { header: 'Sent Date/Time' },
  { header: '' },
];

export const DWELLING_TYPE = [
  { label: 'Commercial', value: 2 },
  { label: 'Residential', value: 1 },
];

export const PROJECT_TYPE = [
  { label: 'Commercial', value: 2 },
  { label: 'Residential', value: 1 },
];

export const SCHEDULE_TYPE = [
  { label: 'Assessment', value: 0 },
  { label: 'Installation', value: 1 },
];

export const ACTIVITY_COLS = [
  { header: 'Name' },
  { header: 'Scheme' },
  // { header: 'Discount' },
  { header: 'Aggregator' },
  { header: '' },
];

export const AUDIT_ACTIVITY_COLS = [
  { header: 'Name' },
  { header: 'Scheme' },
  // { header: 'Discount' },
  { header: 'Aggregator' },
  { header: '' },
];

export const ACTIVITIES_COLS = [
  { header: 'Scheme' },
  { header: 'Activity' },
  { header: 'Device Type' },
];

export const ASSESSMENT_COLS = [
  { header: 'Name' },
  { header: 'Value' },
  { header: '' },
  { header: '' },
];

export const BILL_MATERIAL_COLS = [
  { header: 'Category' },
  { header: 'Brand' },
  { header: 'Model' },
  // { header: 'Price' },
  { header: 'Quantity' },
  { header: 'Total Price' },
  { header: '' },
];

export const CATALOGUE_COLS = [
  { header: 'Category' },
  { header: 'Brand' },
  { header: 'Model' },
  { header: 'Price' },
  { header: 'Quantity' },
];

export const QUOTE_COLS = [
  { header: 'Text' },
  { header: 'Price' },
  { header: '' },
];

export const AUDIT_COLS = [
  { header: 'Project Name' },
  { header: 'Job List' },
  { header: 'Location' },
  { header: 'Dwelling Type' },
  { header: 'Status' },
  // { header: '' },
];

export const AUDIT_JOB_COLS = [
  { header: 'Name' },
  { header: 'Value' },
  { header: 'Meta Data' },
  { header: 'Reject' },
  { header: 'Pass' },
  { header: '' },
  { header: '' },
];

export const PRODUCT_CATALOGUE = [
  { header: 'Model' },
  { header: 'Manufacture' },
  { header: 'Catagory' },
  { header: '' },
  // { header: '' },
];

export const PRICE_COLS = [
  { header: 'Model' },
  { header: 'Manufacture' },
  { header: 'Catagory' },
  { header: 'From Date' },
  { header: 'To Date' },
  { header: 'Cost Price' },
  { header: 'Margin %' },
  { header: 'Margin Amount' },
  { header: 'RRP' },
  { header: '' },
];

export const SERVICE_CATALOGUE = [
  { header: 'Service Item' },
  { header: 'Price' },
  { header: '' },
];

export const USER_COLS = [
  { header: 'User Name' },
  { header: 'Email' },
  { header: 'Access For' },
  { header: 'Status' },
  { header: '' },
];

export const SCHEDULE_SLOT_TIME = [
  { hour: 8, label: '8:00 am' },
  { hour: 9, label: '9:00 am' },
  { hour: 10, label: '10:00 am' },
  { hour: 11, label: '11:00 am' },
  { hour: 12, label: '12:00 pm' },
  { hour: 13, label: '1:00 pm' },
  { hour: 14, label: '2:00 pm' },
  { hour: 15, label: '3:00 pm' },
  { hour: 16, label: '4:00 pm' },
  { hour: 17, label: '5:00 pm' },
  { hour: 18, label: '6:00 pm' },
  { hour: 19, label: '7:00 pm' },
  { hour: 20, label: '8:00 pm' },
];

export const CERTIFICATE_VALUE = [
  { label: 'ACCU', value: 'ACCU' },
  { label: 'ESC', value: 'ESC' },
  { label: 'LGC', value: 'LGC' },
  { label: 'PRC', value: 'PRC' },
  { label: 'STC', value: 'STC' },
  { label: 'VEEC', value: 'VEEC' },
];

export const TASK_COLS = [
  { field: 'TaskName', header: 'Task Name' },
  { field: 'deviceType', header: 'Device Type' },
  { field: '', header: 'Replace / New' },
  { field: '', header: 'Service Catalogue' },
  { field: '', header: '' },
];

export const CATEGORIES = [
  { label: 'Replace', value: 'replace' },
  { label: 'New', value: 'new' },
];

export const DEVICE_TYPE = [
  { label: 'HWHEATPUMP', value: 'HWHEATPUMP' },
  { label: 'LED', value: 'LED' },
  { label: 'SOLAR', value: 'SOLAR' },
];

export const ABN_URL =
  'https://abr.business.gov.au/json/AbnDetails.aspx?callback=JSONP_CALLBACK&guid=f5403340-360c-483d-ae43-ea38cb140ca0&noCacheIE=1680516399638';
